// Generated by Framer (63c002f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useActiveVariantCallback, useComponentViewport, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["Vi00l1N5X", "eHerZn9K2", "x9atrJV_L"];

const serializationHash = "framer-C6mRK"

const variantClassNames = {eHerZn9K2: "framer-v-1givshn", Vi00l1N5X: "framer-v-unof9l", x9atrJV_L: "framer-v-19p98vi"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {delay: 0, duration: 0.5, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "Vi00l1N5X", "Variant 2": "eHerZn9K2", "Variant 3": "x9atrJV_L"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "Vi00l1N5X"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "Vi00l1N5X", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear12j3b2i = activeVariantCallback(async (...args) => {
await delay(() => setVariant("eHerZn9K2"), 5000)
})

const onAppear19clr31 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("x9atrJV_L"), 5000)
})

const onAppear3kikh5 = activeVariantCallback(async (...args) => {
await delay(() => setVariant("Vi00l1N5X"), 5000)
})

useOnVariantChange(baseVariant, {default: onAppear12j3b2i, eHerZn9K2: onAppear19clr31, x9atrJV_L: onAppear3kikh5})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", pixelHeight: 860, pixelWidth: 1360, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/8IJIy1stbUTWCrc7O6Fs5dillk.png", srcSet: "https://framerusercontent.com/images/8IJIy1stbUTWCrc7O6Fs5dillk.png?scale-down-to=512 512w,https://framerusercontent.com/images/8IJIy1stbUTWCrc7O6Fs5dillk.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/8IJIy1stbUTWCrc7O6Fs5dillk.png 1360w"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-unof9l", className, classNames)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"Vi00l1N5X"} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({eHerZn9K2: {"data-framer-name": "Variant 2", background: {alt: "", fit: "fill", pixelHeight: 860, pixelWidth: 1360, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/S5WMWp09NspM2oyXQ7nosZ1f3I.png", srcSet: "https://framerusercontent.com/images/S5WMWp09NspM2oyXQ7nosZ1f3I.png?scale-down-to=512 512w,https://framerusercontent.com/images/S5WMWp09NspM2oyXQ7nosZ1f3I.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/S5WMWp09NspM2oyXQ7nosZ1f3I.png 1360w"}}, x9atrJV_L: {"data-framer-name": "Variant 3", background: {alt: "", fit: "fill", pixelHeight: 860, pixelWidth: 1360, sizes: componentViewport?.width || "100vw", src: "https://framerusercontent.com/images/4OHFW0YZaOJSBtEGeW7Vtko9qKQ.png", srcSet: "https://framerusercontent.com/images/4OHFW0YZaOJSBtEGeW7Vtko9qKQ.png?scale-down-to=512 512w,https://framerusercontent.com/images/4OHFW0YZaOJSBtEGeW7Vtko9qKQ.png?scale-down-to=1024 1024w,https://framerusercontent.com/images/4OHFW0YZaOJSBtEGeW7Vtko9qKQ.png 1360w"}}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-C6mRK.framer-cb7jsx, .framer-C6mRK .framer-cb7jsx { display: block; }", ".framer-C6mRK.framer-unof9l { height: 800px; overflow: hidden; position: relative; width: 1280px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 800
 * @framerIntrinsicWidth 1280
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"eHerZn9K2":{"layout":["fixed","fixed"]},"x9atrJV_L":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramersfUh_Av0c: React.ComponentType<Props> = withCSS(Component, css, "framer-C6mRK") as typeof Component;
export default FramersfUh_Av0c;

FramersfUh_Av0c.displayName = "HERO IMAGE";

FramersfUh_Av0c.defaultProps = {height: 800, width: 1280};

addPropertyControls(FramersfUh_Av0c, {variant: {options: ["Vi00l1N5X", "eHerZn9K2", "x9atrJV_L"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(FramersfUh_Av0c, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})